import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../modules';
import { setFontSizeData } from '../../modules/fontSize';

type FontSizeProps = {
  state: boolean;
  handleFontSizeState: (state: boolean) => void;
};

const FontSize = ({ state, handleFontSizeState }: FontSizeProps) => {
  const fontWrapRef = useRef<HTMLDivElement>(null);

  const fontSizeData = useSelector((state: RootState) => state.fontSize);

  const dispatch = useDispatch();

  const handleSelectedCircle = (index: number) => {
    dispatch(setFontSizeData(index));
    // localStorage.setItem('fontSizeIndex', index.toString());
  };

  useEffect(() => {
    const listener = (
      e: React.BaseSyntheticEvent | MouseEvent | TouchEvent
    ) => {
      if (
        !fontWrapRef.current ||
        fontWrapRef.current.contains(e.target) ||
        e.target.dataset.id === 'font'
      ) {
        return;
      }

      handleFontSizeState(false);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [handleFontSizeState]);

  return (
    <FontSizeWrap state={state} ref={fontWrapRef}>
      <HeaderWrap>
        <Close
          src="https://kr.object.gov-ncloudstorage.com/chatbot-front/seocho/Contents/Close.svg"
          alt={'닫기'}
          onClick={() => handleFontSizeState(false)}
        />
      </HeaderWrap>
      <Title>{'폰트 크기 설정'}</Title>
      <Contents>
        <Line />
        {fontSizeData.map((item, index) =>
          item.selected ? (
            <CircleWrap
              key={index}
              src={`https://kr.object.gov-ncloudstorage.com/chatbot-front/seocho/Contents/${item.id}_active.svg`}
              onClick={() => handleSelectedCircle(index)}
            ></CircleWrap>
          ) : (
            <CircleWrap
              key={index}
              src={`https://kr.object.gov-ncloudstorage.com/chatbot-front/seocho/Contents/${item.id}.svg`}
              onClick={() => handleSelectedCircle(index)}
            ></CircleWrap>
          )
        )}
      </Contents>
    </FontSizeWrap>
  );
};

export default FontSize;

const FontSizeWrap = styled.div<{ state: boolean }>`
  background: #edf2f8;
  border-radius: 20px 20px 0px 0px;
  /* width: -webkit-fill-available; */
  width: 335px;
  height: 159px;
  position: fixed;
  bottom: 0;
  z-index: 1002;
  filter: drop-shadow(0px -2px 4px rgba(0, 0, 0, 0.25));
  padding: 0 20px 0 20px;
  transform: ${(props) =>
    props.state ? 'translateY(0)' : 'translateY(159px)'};
  transition: transform 0.5s ease-out;
`;
const HeaderWrap = styled.div`
  width: 100%;
  height: 25px;
  position: relative;
`;
const Title = styled.h3`
  font-weight: 500;
  font-size: 15px;
  letter-spacing: -0.02em;
  color: #77738d;
  margin: 0;
`;
const Close = styled.img`
  width: 16px;
  height: 16px;
  position: absolute;
  top: 12px;
  right: -12px;
  cursor: pointer;
`;
const Contents = styled.div`
  width: 300px;
  height: 50px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 28px auto 0 auto;
`;
const Line = styled.div`
  width: 300px;
  height: 2px;
  background: #c2c7d5;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -60%);
`;
const Text = styled.p<{ index: number; selected: boolean }>`
  font-size: ${(props) => `${(props.index + props.index) * 2 + 12}px;`}
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 2px 0 0 0;
  font-weight: 700;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${(props) => (props.selected ? '#ffffff' : '#77738d')};
  height: 100%;
`;
const CircleWrap = styled.img`
  z-index: 1000;
  cursor: pointer;
`;

import './fonts/fonts.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import GlobalStyle from './styles/global-styles';
import rootReducer from './modules';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Provider } from 'react-redux';
import { StylesProvider } from '@material-ui/styles';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// ThemeProvider as MuiThemeProvider
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import './index.css';
import theme from './styles/clientStyles';

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

ReactDOM.render(
  <Provider store={store}>
    <StylesProvider injectFirst>
      <React.StrictMode>
        <GlobalStyle />
        <StyledThemeProvider theme={theme}>
          <Router>
            <Switch>
              <Route path="/" component={App} />
              <Route exact path="/:id" component={App} />
            </Switch>
          </Router>
        </StyledThemeProvider>
      </React.StrictMode>
    </StylesProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// import React, {useState,useEffect} from "react";
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Header from '../../components/Mobile/Haeder';
import Input from '../../components/Mobile/Input';
import Chatting from '../../components/Mobile/Chatting';
import Modal from '../../components/Mobile/Modal';
import { RootState } from '../../modules';
import { ClientNameProps } from '../../types';
import { useEffect, useRef } from 'react';

const Mobile = () => {
  const heightRef = useRef<HTMLDivElement>(null);

  const { clientName }: { clientName: ClientNameProps } = useSelector(
    (state: RootState) => ({
      clientName: state.defaults.clientName,
    })
  );

  useEffect(() => {
    const handleHeight = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    handleHeight();
    window.addEventListener('resize', handleHeight);

    return () => {
      window.removeEventListener('resize', handleHeight);
    };
  }, []);

  return (
    <TotalWrap ref={heightRef}>
      <EmptyWrapLeft></EmptyWrapLeft>
      <Wrap clientname={clientName}>
        <Header />
        <Chatting />
        <Input />
      </Wrap>
      <EmptyWrapRight></EmptyWrapRight>
      <Modal></Modal>
    </TotalWrap>
  );
};

export default Mobile;
interface WrapProps {
  clientname: string;
}
const TotalWrap = styled.div`
  display: flex;
  justify-content: center;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
`;
const Wrap = styled.div<WrapProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 375px;
  width: 375px;
  max-width: 375px;
  height: 100%;
  background-color: #fff;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
`;

const EmptyWrapLeft = styled.div`
  width: 100%;
  background-color: #333333;
  height: 100%;
  z-index: 9999999999999;
`;
const EmptyWrapRight = styled.div`
  width: 100%;
  background-color: #333333;
  height: 100%;
  z-index: 9999999999999;
`;

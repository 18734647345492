import styled from 'styled-components';
import { useRef, useState } from 'react';
import { ReactComponent as SendSVG } from '../../assets/input/Send.svg';
import { getTime, responseParsing } from '../../api/functions';
import { postMessage2 } from '../../api/axiosFunction';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../modules';

import { addRendingData, ChattingType } from '../../modules/chatting';
import { closeVisualPage } from '../../modules/visualPage';
import { offState, switchState } from '../../modules/bookMark';
import { addUserBubbles } from '../../modules/userChatting';
import { addSearchIntent, IntentType } from '../../modules/intent';
import Intent from './Intent';
import { ClientNameProps, StyleThemeProps } from '../../types';
import BookMark from './BookMark';
import { answerGenerator } from '../../modules/thunks';

interface pressInput {
  key: string;
}

const Input = () => {
  // Redux state
  const { clientName }: { clientName: ClientNameProps } = useSelector(
    (state: RootState) => ({
      clientName: state.defaults.clientName,
    })
  );
  const { userChatting } = useSelector((state: RootState) => ({
    userChatting: state.userChatting,
  }));
  const { searchIntents } = useSelector((state: RootState) => ({
    searchIntents: state.intent,
  }));
  const { defaults } = useSelector((state: RootState) => ({
    defaults: state.defaults,
  }));

  // Redux Dispatch Functions
  const dispatch = useDispatch();
  const onSwitchBookMark = () => {
    dispatch(switchState());
  };
  const onOffState = () => {
    dispatch(offState());
  };
  const onUserData = (message: string, list: ChattingType) => {
    dispatch(addUserBubbles(message));
    dispatch(addRendingData(list));
  };
  const OnCloseVisualPage = () => {
    dispatch(closeVisualPage());
  };
  const searchIntentList = (list: IntentType) => {
    dispatch(addSearchIntent(list));
  };

  const inputRef = useRef<HTMLInputElement>(null);

  // React
  const [inputText, setInputText] = useState('');
  const [inputState, setInputState] = useState<boolean>(false);
  const [selectedListText, setSelectedListText] = useState('');
  const [selectedListIndex, setSelectedListIndex] = useState(0);
  const [inputButtonState, setInputButtonState] = useState(false);
  const [checkResponseEmail, setCheckResponseEmail] = useState(false);

  const focusInput = () => {
    setInputState(true);
    onOffState();
  };

  const blurInput = () => {
    setInputState(false);
    if (checkResponseEmail) {
      onOffState();
    } else {
      onSwitchBookMark();
    }
  };

  const changeInput = (e: any) => {
    setInputText(e.target.value);
    resetSeletedList();
  };

  const clickSendBtn = () => {
    if (inputText.trim() !== '') {
      postInputUpdate(inputText, null);
      resetSeletedList();
    }
  };

  const resetSeletedList = () => {
    setSelectedListIndex(0);
    searchIntents.forEach((list) => (list.selected = false));
  };

  const selectSearchWord = (arrowKey: string) => {
    if (!arrowKey || searchIntents.length === 0) return;

    let listIndex = 0;
    if (arrowKey === 'ArrowUp') {
      listIndex =
        selectedListIndex === 0 ? searchIntents.length : selectedListIndex - 1;

      // selected cycle
      if (listIndex === 0) listIndex = searchIntents.length;
    }

    if (arrowKey === 'ArrowDown') {
      listIndex = selectedListIndex === 0 ? 1 : selectedListIndex + 1;

      // selected cycle
      if (listIndex === searchIntents.length + 1) listIndex = 1;
    }

    setSelectedListIndex(listIndex);

    searchIntents.forEach((list, index) => {
      if (listIndex === index + 1) {
        list.selected = true;
        setSelectedListText(list.name);
      } else {
        list.selected = false;
      }
    });

    searchIntentList(searchIntents);
  };

  const onKeyDownArrowBtn = (e: { key: string; target: any }) => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      const checkEntries = checkDuplicateEntries(100);
      if (!checkEntries) selectSearchWord(e.key);

      freezeTextCursor(e);
    }
  };

  const onPressInput = ({ key }: pressInput) => {
    if (key === 'Enter' && inputText.trim() !== '') {
      const text = selectedListIndex === 0 ? inputText : selectedListText;

      postInputUpdate(text, null);
      resetSeletedList();
    }
  };

  const postInputUpdate = async (input: string, id: string | null) => {
    OnCloseVisualPage();
    setInputText('');
    onUserData(input, [
      { type: 'User', id: userChatting.length, time: getTime() },
    ]);
    if (checkResponseEmail) {
      setInputButtonState(true);
      inputRef?.current?.setAttribute('placeholder', '잠시만 기다려 주세요...');
    }

    const {
      data: { response },
    } = await postMessage2(input, id, 'text');
    if (response?.contents[0]?.message === '👉 문의 내용을 입력해주세요.') {
      setCheckResponseEmail(true);
    }
    if (checkResponseEmail) {
      setCheckResponseEmail(false);
      setInputButtonState(false);
      inputRef?.current?.focus();
      inputRef?.current?.setAttribute('placeholder', defaults.placeholder);
    }
    const responseData = responseParsing(response);
    responseData && dispatch(answerGenerator(responseData));
  };

  return (
    <Wrap clientname={clientName}>
      <InputWrap clientname={clientName}>
        <Intent
          input={inputText}
          setInput={setInputText}
          postInputUpdate={postInputUpdate}
        ></Intent>
        <PostWrap clientname={clientName} inputstate={inputState}>
          <TextInput
            inputstate={inputState}
            clientname={clientName}
            value={inputText}
            onFocus={focusInput}
            onBlur={blurInput}
            onChange={changeInput}
            onKeyPress={onPressInput}
            onKeyDown={onKeyDownArrowBtn}
            placeholder={defaults.placeholder}
            title={defaults.placeholder}
            disabled={inputButtonState}
            ref={inputRef}
          />
          <SendButton
            clientname={clientName}
            onClick={clickSendBtn}
            inputstate={inputState}
          >
            <SendSVG />
          </SendButton>
        </PostWrap>
      </InputWrap>
      <BookMark />
    </Wrap>
  );
};

export default Input;

interface Image {
  inputstate: boolean;
  clientname: string;
}

interface PostProps {
  inputstate: boolean;
  clientname: string;
}

interface TextInputProps {
  inputstate: boolean;
  clientname: string;
}

const Wrap = styled.div<StyleThemeProps>`
  position: fixed;
  bottom: 0px;
  width: 100%;
  max-width: 375px;
  display: flex;
  flex-direction: column-reverse;
  z-index: 999;
  background: ${({ theme, clientname }) =>
    theme[clientname].input?.inputWrap?.background};
`;
const InputWrap = styled.div<StyleThemeProps>`
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${({ theme, clientname }) =>
    theme[clientname].input.wrap.background};
  z-index: 999;
`;

const PostWrap = styled.div<PostProps>`
  width: calc(100% - 36px);
  height: calc(100% - 22px);
  border: ${({ inputstate, theme, clientname }) =>
    inputstate
      ? theme[clientname].input.postWrap.border.on
      : theme[clientname].input.postWrap.border.off};
  box-sizing: border-box;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ inputstate, theme, clientname }) =>
    inputstate
      ? theme[clientname].input.postWrap?.background?.on || '#ffffff'
      : theme[clientname].input.postWrap?.background?.off || '#ffffff'};
`;

const TextInput = styled.input<TextInputProps>`
  border: 0px;
  width: 100%;
  height: 23px;
  font-family: 'Noto Sans KR', Poppins;
  font-size: 16px;
  line-height: 23px;
  margin-left: 24px;
  outline: none;
  background-color: ${({ inputstate, theme, clientname }) =>
    inputstate
      ? theme[clientname].input?.textInput?.background?.on
      : theme[clientname].input?.textInput?.background?.off};
  color: ${({ inputstate, theme, clientname }) =>
    inputstate
      ? theme[clientname].input?.textInput?.color?.on
      : theme[clientname].input?.textInput?.color?.off};
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${({ theme, clientname }) =>
      theme[clientname].input?.textInput?.placeholder ||
      'rgba(11, 11, 11, 0.2)'};
  }
`;

const SendButton = styled.div<Image>`
  width: 30px;
  height: 22px;
  border: 0px;
  padding: 0px;
  fill: red;
  /* background-color: white; */
  g {
    opacity: ${({ inputstate }) => (inputstate ? '1' : '0.2')};
  }
  path {
    fill: ${({ theme, clientname }) =>
      theme[clientname].input.sendbutton.background};
  }
  margin-right: 13px;
`;

const checkDuplicateEntries = (() => {
  let check = true;

  return (interval: number) => {
    if (!check) return true;

    check = false;

    setTimeout(() => (check = true), interval);

    return check;
  };
})();

const freezeTextCursor = (event: { target: any }) => {
  const selectionEnd = event.target.selectionEnd;

  setTimeout(() => {
    event.target.selectionStart = event.target.selectionEnd = selectionEnd;
  }, 0);
};

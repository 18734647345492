import { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../modules';
import ToastPopup from './ToastPopup';
import { NotoSans17 } from '../../styles/fontStyls';
import MenuComponent from './Menu';
import { ClientNameProps, StyleThemeProps } from '../../types';
import Help from './Help';
import FontSize from './FontSize';

const Header = () => {
  const { clientName }: { clientName: ClientNameProps } = useSelector(
    (state: RootState) => ({
      clientName: state.defaults.clientName,
    })
  );
  const { header } = useSelector((state: RootState) => ({
    header: state.defaults.name,
  }));
  const [errorState, setErrorState] = useState(false);
  const [menuState, setMenuState] = useState(false);
  const [helpState, setHelpState] = useState(false);
  const [fontSizeState, setFontSizeState] = useState<boolean>(false);

  const handleHelpState = (state: boolean) => {
    setHelpState(state);
  };

  const handleFontSizeState = (state: boolean) => {
    setFontSizeState(state);
  };

  return (
    <>
      <HeaderWrap clientname={clientName}>
        {menuState && (
          <MenuComponent
            state={menuState}
            setState={setMenuState}
          ></MenuComponent>
        )}
        <CompanyWrap clientname={clientName}>
          {!helpState && (
            <>
              <FontSizeWrap
                clientname={clientName}
                onClick={() => handleFontSizeState(!fontSizeState)}
                data-id={'font'}
              >
                <FontImg
                  src={`https://kr.object.gov-ncloudstorage.com/chatbot-front/${clientName}/Contents/Title.svg`}
                  clientname={clientName}
                  data-id={'font'}
                  alt={`폰트 크기 설정`}
                />
              </FontSizeWrap>
              <TitleWrap clientname={clientName}>
                <LogoImg
                  src={`https://kr.object.gov-ncloudstorage.com/chatbot-front/${clientName}/header/Title.png`}
                  clientname={clientName}
                  alt={`로고`}
                />
                <Title clientname={clientName}>{header}</Title>
              </TitleWrap>
              <ContentWrap clientname={clientName}>
                <HomeImg
                  src={`https://kr.object.gov-ncloudstorage.com/chatbot-front/${clientName}/header/Home.svg`}
                  clientname={clientName}
                  onClick={() => {
                    window.location.href = `${window.location.origin}${window.location.pathname}`;
                  }}
                  alt={`홈`}
                ></HomeImg>
                <HelpImg
                  src={`https://kr.object.gov-ncloudstorage.com/chatbot-front/${clientName}/header/Help.svg`}
                  clientname={clientName}
                  onClick={() => setHelpState(true)}
                  alt={`도움말`}
                ></HelpImg>
              </ContentWrap>
            </>
          )}
        </CompanyWrap>

        {errorState ? <ToastPopup /> : ''}
      </HeaderWrap>
      <Help state={helpState} handleHelpState={handleHelpState} />
      <FontSize
        state={fontSizeState}
        handleFontSizeState={handleFontSizeState}
      />
    </>
  );
};

export default Header;

const HeaderWrap = styled.div<StyleThemeProps>`
  width: 375px;
  height: 58px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-shadow: ${({ theme, clientname }) =>
    theme[clientname].header.wrap?.boxShadow ||
    '0 6px 4px 0 rgb(46 47 48 / 4%)'};
  background: ${({ theme, clientname }) =>
    theme[clientname].header.wrap.background};
  border-bottom: ${({ theme, clientname }) =>
    theme[clientname].header.wrap?.borderBottom || 'none'};
  z-index: 1000;
  position: fixed;
  top: 0;
`;
const CompanyWrap = styled.button<StyleThemeProps>`
  width: 100%;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0px;
  background: ${({ theme, clientname }) =>
    theme[clientname].header.company.background};
`;
const TitleWrap = styled.div<StyleThemeProps>`
  display: flex;
  align-items: center;
  /* transform: translateY(-2px); */
  margin: 0 auto;
`;
const Title = styled.div<StyleThemeProps>`
  ${NotoSans17}
  color:${({ theme, clientname }) => theme[clientname].header.title.color};
  margin-left: ${({ theme, clientname }) =>
    theme[clientname].header?.title?.marginLeft || '12px'};
  margin-bottom: ${({ theme, clientname }) =>
    theme[clientname].header?.title?.marginBottom || '0'};
  font-size: 17px;
  position: ${({ theme, clientname }) =>
    theme[clientname].header?.title?.position};
  top: ${({ theme, clientname }) => theme[clientname].header?.title?.top};
  left: ${({ theme, clientname }) => theme[clientname].header?.title?.left};
`;
const LogoImg = styled.img<StyleThemeProps>`
  path {
    fill: ${({ theme, clientname }) => theme[clientname].header.title.color};
  }
  max-height: 27px;
  position: ${({ theme, clientname }) =>
    theme[clientname].header?.img?.position};
  left: ${({ theme, clientname }) => theme[clientname].header?.img?.left};
`;
const ContentWrap = styled.div<StyleThemeProps>`
  display: flex;
  position: absolute;
  right: 6px;
  bottom: 6px;
`;
const HomeImg = styled.img<StyleThemeProps>`
  max-height: ${({ theme, clientname }) =>
    theme[clientname].header?.img?.maxHeight};
  position: ${({ theme, clientname }) =>
    theme[clientname].header?.img?.position};
  left: ${({ theme, clientname }) => theme[clientname].header?.img?.left};
  cursor: pointer;
  margin: 0 10px 0 0;
  width: 23px;
  height: 20px;
`;
const HelpImg = styled.img<StyleThemeProps>`
  max-height: ${({ theme, clientname }) =>
    theme[clientname].header?.img?.maxHeight};
  position: ${({ theme, clientname }) =>
    theme[clientname].header?.img?.position};
  left: ${({ theme, clientname }) => theme[clientname].header?.img?.left};
  cursor: pointer;
  width: 20px;
  height: 20px;
`;
const FontSizeWrap = styled.div<StyleThemeProps>`
  ${NotoSans17}
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  width: 55px;
  margin: 0 0 3px 0;
  position: absolute;
  left: 6px;
  bottom: 6px;
`;
const FontImg = styled.img<StyleThemeProps>``;

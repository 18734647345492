import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { RootState } from '../../modules';
import { NotoSans17 } from '../../styles/fontStyls';
import { ClientNameProps, StyleThemeProps } from '../../types';

type HelpProps = {
  state: boolean;
  handleHelpState: (state: boolean) => void;
};

const Help = ({ state, handleHelpState }: HelpProps) => {
  const { clientName }: { clientName: ClientNameProps } = useSelector(
    (state: RootState) => ({
      clientName: state.defaults.clientName,
    })
  );
  const { header } = useSelector((state: RootState) => ({
    header: state.defaults.name,
  }));

  return (
    <>
      {state && (
        <>
          <HeaderWrap
            clientname={clientName}
            onClick={() => handleHelpState(false)}
          >
            <CompanyWrap clientname={clientName}>
              <FontSizeWrap>
                <FontImg
                  src={`https://kr.object.gov-ncloudstorage.com/chatbot-front/${clientName}/Contents/Title.svg`}
                  clientname={clientName}
                  data-id={'font'}
                />
                <FontLine>
                  <FontGuideWrap>
                    <TextGuide>{'폰트 크기 설정 버튼'}</TextGuide>
                  </FontGuideWrap>
                </FontLine>
              </FontSizeWrap>
              <TitleWrap clientname={clientName}>
                <LogoImg
                  src={`https://kr.object.gov-ncloudstorage.com/chatbot-front/${clientName}/header/Title.png`}
                  clientname={clientName}
                />
                <Title clientname={clientName}>{header}</Title>
              </TitleWrap>
              <ContentWrap clientname={clientName}>
                <HomeWrap>
                  <HomeImg
                    src={`https://kr.object.gov-ncloudstorage.com/chatbot-front/${clientName}/header/Home.svg`}
                    clientname={clientName}
                  ></HomeImg>
                  <HomeLine>
                    <HomeGuideWrap>
                      <TextGuide>{'홈 버튼'}</TextGuide>
                    </HomeGuideWrap>
                  </HomeLine>
                </HomeWrap>

                <HelpWrap>
                  <HelpImg
                    src={`https://kr.object.gov-ncloudstorage.com/chatbot-front/${clientName}/header/Help.svg`}
                    clientname={clientName}
                  ></HelpImg>
                  <HelpLine>
                    <HelpGuideWrap>
                      <TextGuide>{'도움말 버튼'}</TextGuide>
                    </HelpGuideWrap>
                  </HelpLine>
                </HelpWrap>
              </ContentWrap>
            </CompanyWrap>
            <Background onClick={() => handleHelpState(false)}></Background>
          </HeaderWrap>
        </>
      )}
    </>
  );
};

export default Help;

const Background = styled.div`
  background: #000000;
  opacity: 0.7;
  position: absolute;
  top: 0;
  width: 375px;
  height: 100vh;
  z-index: 1000;
`;
const FontGuideWrap = styled.div`
  width: max-content;
  padding: 0 3px;
  height: 24px;
  background: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: 0px 0px 4px 1px rgba (150, 150, 150, 0.25);
  border-radius: 10px;
  position: absolute;
  top: 19px;
  left: -15px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const HomeGuideWrap = styled.div`
  width: max-content;
  padding: 0 3px;
  height: 24px;
  background: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: 0px 0px 4px 1px rgba (150, 150, 150, 0.25);
  border-radius: 10px;
  position: absolute;
  top: -50%;
  left: -43px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
`;
const HelpGuideWrap = styled.div`
  width: max-content;
  padding: 0 3px;
  height: 24px;
  background: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: 0px 0px 4px 1px rgba (150, 150, 150, 0.25);
  border-radius: 10px;
  position: absolute;
  top: 24px;
  right: 0;
  transform: translateX(20%);
  display: flex;
  align-items: center;
  justify-content: center;
`;
const TextGuide = styled.p`
  ${NotoSans17}
  font-weight: 500;
  font-size: 12px;
  text-align: center;
  color: #235eaa;
  height: inherit;
  line-height: 25px;
`;

const HeaderWrap = styled.div<StyleThemeProps>`
  width: 375px;
  height: 58px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  position: fixed;
  top: 0;
`;
const TitleWrap = styled.div<StyleThemeProps>`
  display: flex;
  margin: 0 auto;
`;
const Title = styled.div<StyleThemeProps>`
  ${NotoSans17}
  color:${({ theme, clientname }) => theme[clientname].header.title.color};
  margin-left: ${({ theme, clientname }) =>
    theme[clientname].header?.title?.marginLeft || '12px'};
  margin-bottom: ${({ theme, clientname }) =>
    theme[clientname].header?.title?.marginBottom || '0'};
  font-size: ${({ theme, clientname }) =>
    theme[clientname].header?.title?.fontSize};
  position: ${({ theme, clientname }) =>
    theme[clientname].header?.title?.position};
  top: ${({ theme, clientname }) => theme[clientname].header?.title?.top};
  left: ${({ theme, clientname }) => theme[clientname].header?.title?.left};
`;
const LogoImg = styled.img<StyleThemeProps>`
  path {
    fill: ${({ theme, clientname }) => theme[clientname].header.title.color};
  }
  max-height: 27px;
  position: ${({ theme, clientname }) =>
    theme[clientname].header?.img?.position};
  left: ${({ theme, clientname }) => theme[clientname].header?.img?.left};
`;
const CompanyWrap = styled.button<StyleThemeProps>`
  width: 100%;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0px;
  background: ${({ theme, clientname }) =>
    theme[clientname].header.company.background};
`;

const ContentWrap = styled.div<StyleThemeProps>`
  display: flex;
  z-index: 1001;
  align-items: center;
  position: absolute;
  right: 6px;
  bottom: 4px;
`;
const HomeImg = styled.img<StyleThemeProps>`
  max-height: ${({ theme, clientname }) =>
    theme[clientname].header?.img?.maxHeight};
  position: ${({ theme, clientname }) =>
    theme[clientname].header?.img?.position};
  left: ${({ theme, clientname }) => theme[clientname].header?.img?.left};
  width: 23px;
  height: 20px;
`;
const HelpImg = styled.img<StyleThemeProps>`
  max-height: ${({ theme, clientname }) =>
    theme[clientname].header?.img?.maxHeight};
  position: ${({ theme, clientname }) =>
    theme[clientname].header?.img?.position};
  left: ${({ theme, clientname }) => theme[clientname].header?.img?.left};
  width: 20px;
  height: 20px;
`;
const FontSizeWrap = styled.div`
  ${NotoSans17}
  display: flex;
  align-items: flex-end;
  width: 35px;
  height: 27px;
  z-index: 1001;
  border: 1px solid white;
  margin: 0 18px 0 -2px;
  padding: 0 0 0 1px;
  position: absolute;
  left: 6px;
  bottom: 3px;
`;
const HomeWrap = styled.div`
  border: 1px solid white;
  position: relative;
  margin: 0 8px 0 -2px;
  padding: 1px 1px;
  display: flex;
  align-items: center;
`;
const HelpWrap = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
`;
const HomeLine = styled.div`
  width: 12px;
  height: 0px;
  border: 1px solid white;
  position: absolute;
  top: 50%;
  left: -15px;
  transform: translateY(-50%);
`;
const HelpLine = styled.div`
  width: 0px;
  height: 23px;
  border: 1px solid white;
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
`;

const FontLine = styled.div`
  width: 0px;
  height: 18px;
  border: 1px solid white;
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
`;
const FontImg = styled.img<StyleThemeProps>`
  transform: translateY(-5px);
`;

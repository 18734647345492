const FONTSIZESTATE = 'fontSize/FontSizeState';

export const setFontSizeData = (index: number) => ({
  type: FONTSIZESTATE,
  index,
});

export const fontState = () => ({ state: FONTSIZESTATE });
const initailState: FontSizeType[] = [
  { id: 'small', text: '가', selected: false, fontSize: '15px' },
  { id: 'normal', text: '가', selected: true, fontSize: '16px' },
  { id: 'large', text: '가', selected: false, fontSize: '18px' },
  { id: 'huge', text: '가', selected: false, fontSize: '20px' },
];

const fontSizeState = (state: FontSizeType[] = initailState, action: any) => {
  switch (action.type) {
    case FONTSIZESTATE:
      return state.map((item, index) => {
        if (action.index === index) {
          return { ...item, selected: true };
        } else {
        }
        return { ...item, selected: false };
      });

    default:
      return state;
  }
};

export default fontSizeState;

interface FontSizeType {
  id: string;
  text: string;
  selected: boolean;
  fontSize: string;
}
